table.scheduler{
    margin: 0px 0px 0px 0px;
}
table.scheduler > tbody{
    background-color: #ffffff;
}

table.scheduler > thead .ant-row-flex .header2-text
{
    font-weight: bold;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    background-color: #fccc92 !important;
    color: #000000 !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
.ant-radio-button-wrapper:first-child
{
    border-left: 1px solid transparent;
    border-radius: 4px 0 0 4px;
}

.ant-radio-button-wrapper:last-child
{
    border-right: 1px solid transparent;
    border-radius: 4px 0 0 4px;
}

.ant-radio-button-wrapper{
    border: 0;
    border-color: transparent !important;
    height: 40px !important;
    display: inline-flex !important;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
}

.ant-row-flex-space-between
{
    justify-content: space-evenly !important;
}

table.resource-table > thead th, table.scheduler-bg-table  > thead th
{
    font-weight: bold;
}

.anticon-right:after{
    content: '>' !important;
    font-size: 20px;
    vertical-align: baseline;
}

.anticon-left:after{
    content: '<' !important;
    font-size: 20px;
    vertical-align: baseline;
}
.ant-select-arrow::before{
    display: none;
}

.header3-text > div > div{
    word-spacing: 1000px;
    text-align: center;
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
  background-image: url("assets/bg-loginpage.png");
  background-position: top left;
  background-color: #e5e5e5;
  background-repeat: no-repeat;
  background-size: 100% auto;
  overflow-x: hidden;
  overflow-y: hidden;
}
.somethingWrongContainer {
  width: fit-content;
  transform: translate(30vw, 25vh);
  display: flex;
  flex-direction: column;
  align-items: center;
}
h1,
h2,
h3,
h4 {
  font-weight: 300;
}

.app {
  display: flex;
  height: 100vh;
  flex-flow: column;
}

.ServiceActivitySchedulingOuter {
  height: calc(100% - 64px);
  top: 64px;
  position: relative;
}

.ServiceActivitySchedulingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  div[class*="scrollbarStyle"]{
    height: calc(100% - 180px) !important;
  }
}

.ServiceActivitySchedulingSection {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 310px);
}


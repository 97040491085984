.logo-image {
    height: 44px;
    max-height: 44px;
    margin-top: 4px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.appBar {
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: rgba(0,0,0,1) !important;
    position: fixed !important;
    z-index: 999 !important;
    box-shadow: 0px 1px 6px -1px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 4px 0px rgba(0, 0, 0, 0.12) !important;
    
}

.appBar > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.mainNavigationLogo, .mainNavigationFullLogo {
    display: 'flex';
    flex-flow: 'row nowrap';
    flex: 1;
}

@media screen and (max-width: 768px) {
    .user-account{
        
        display: none;            
        
    }
    .navigation-button {
        width: 40px !important;
        min-width: 40px !important;
        padding: 12px 6px !important;
    }
  }

@media screen and (max-width: 480px) {
    .mainNavigationLogo {
        overflow: hidden;
        width: 60px;
        max-width: 60px;
    }
    .mainNavigationLogo img {
        margin-left: 5px !important;
        margin-right: 0px
    }
}
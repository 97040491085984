.appBar {
    background-color: #bbbbbb !important;
    box-shadow: 0 0 0 transparent !important;
}
.appBar > div {
    height: 52px !important;
    min-height: 52px !important;
    padding-right: 0px !important;
}
.menuContent {
    padding: 0 10px !important;
    box-sizing: border-box;
    // margin-bottom: 150px;
    // padding-bottom: 150px;
    // height: 100%;
    height: calc( 100% - 64px - 52px);
}
@media screen and (max-width: 480px) {
    .menuContent {
        padding-bottom: 80px !important;
    }
    .appBar {
        height: 40px;
        padding-left: 10px !important;
    }
    .appBar h1 {
        font-size: 18px !important;
        line-height: 40px !important;
    }
    .appBar > div {
        margin-top: 0px !important;
    }
    .iconCloseButton {
        width: 40px !important;
        height: 40px !important;
        padding: 0px !important;
    }
}
.root {
    flex-grow: 1;
    padding: 15px 0 0 15px;
    height: calc(100% - 15px);
    vertical-align: top;
}

.formDiv {
    min-height: 100%;
}

.grid {
    flex-wrap: wrap;
    // padding-bottom: 30px;
    height: 100%;
    align-items: stretch;
}

.gridItem {
    width: 33%;
}

.header {
    font-size: 18px;
    font-weight: 100;
    display: inline-block;
    padding-bottom: 16px;
    width: 100%;
}

.noHeader {
    display: none;
}

.group {
    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
}

@media screen and (max-width: 480px) {
    .gridItem {
        width: 100% !important;
        max-width: 100% !important;
        flex-basis: 100% !important;
    }

    .gridItem:last-child {
        margin-bottom: 90px;
    }
}
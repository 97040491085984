.rootDiv {
  position: relative;
  padding-bottom: 100px;
  height: 100%;
  width: 100%;
}
.panelFooter {
  width: 100%;
  position: absolute;
  bottom: 0;
  right:0;
}
.clearAllButton {
  width: 100%;
}
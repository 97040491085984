.mainDisplayContainer {
  display: block;
  width: 100%;
  height: calc(100% - 64px);
  top: 64px;
  position: relative;
  .scrollableContainer {
    margin-bottom: 30px;
    display: flex;
  }
  .widgetsContainer {
    padding-right: 75px;
  }
  .reloadContainer {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    right: 20px;
    top: 15px;
  }
  .loading {
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

}

p {
  margin: 0;
}

.bottomNavigationBar {
  height: 25px !important;
  width: 100%;
  position: fixed;
  bottom: 0;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  line-height: 10px;
  text-align: center;
}
.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}

.outer {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.inner {
  width: 100%;
}

.infoSection {
  display: flex;
  flex-basis: 100%;
  padding: 35px;
}

.notificationPaper {
  min-height: 160px;
  min-width: 450px;
  margin: 30px;
  margin-bottom: 15px;
}

.videoPlayer {
  padding: 10px 35px 35px;
}

.videoPlayer > div {
  margin: 0 auto;
}

.video {
  width: 640px;
}

.quickAccessGrid {
  padding-top: 0;
}

.quickAccessGridDefault {
  margin-left: 22px !important;
  margin-top: 22px !important;
}

.quickAccessPaper,
.quickAccessCard {
  height: 140px;
  width: 140px;
  margin: 0 10px 10px 0;
}

.quickAccessCardArea {
  width: 140px;
  height: 140px;
  text-align: center;
}
.quickAccessCardIcon {
  width: 60px;
  height: 60px;
  padding: 15px 40px 5px;
}
.quickAccessCardIcon > i.material-icons {
  font-size: 48px;
}
.quickAccessCardIcon > svg {
  width: 50px;
  height: 50px;
  margin: 5px;
}
.quickAccessCardText {
  display: inline-block !important;
  line-height: normal !important;
  vertical-align: middle !important;
  text-align: center !important;
  font-size: 16px !important;
  width: 100%;
  max-width: 120px;
  padding: 0 10px;
}

.infoContent {
  flex: 24;
  font-size: 16px;
  line-height: 24px;
}

.infoIcon {
  flex: 1;
  margin-left: 35px;
}

.notificationMeta {
  padding-left: 35px;
  padding-bottom: 35px;
  font-size: 14px;
  color: #a9a9a9;
}

.statisticsSection {
  display: flex;
  width: calc(100% - 60px);
  flex-flow: row wrap;
  height: 240px;
  margin-top: 8px !important;
  justify-content: space-between;
}

.statisticsSection > div {
  width: 31% !important;
  margin: 15px 0 0;
  position: relative;
  min-width: 250px;
  min-height: 200px;
}

.statContent {
  min-height: 150px;
  position: relative;
  padding: 28px;
}

.statTitle {
  margin: 0px;
  color: #000000;
  font-size: 18px;
  font-weight: normal;
}

.statNumber {
  position: absolute;
  right: 25px;
  font-size: 40px;
  font-weight: 500;
  bottom: 30px;
}

.statValue {
  position: absolute;
  bottom: 25px;
  right: 25px;
  margin: 0px;
  color: #000000;
  font-size: 64px;
  font-weight: 500;
  text-align: right;
}

.statGraph {
  position: absolute;
  bottom: 20px;
}

.statFlipIcon {
  position: absolute !important;
  top: 0;
  right: 0;
  color: #cccccc !important;
  width: 40px !important;
  height: 40px !important;
  cursor: pointer;
  padding: 0 !important;
}

.statFlipIcon svg {
  color: #cccccc !important;
}

.statFlipIcon:hover svg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.video {
  width: 100% !important;
}

.widgetContainer {
  width: 664px;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .notificationPaper {
    min-width: 350px;
  }
  .statisticsSection {
    height: auto;
    margin-top: 20px;
  }
  .statisticsSection > div {
    width: 100% !important;
    min-height: 206px;
    margin-bottom: 10px;
    min-width: 290px;
  }
  .statisticsSection > div:last-child {
    margin-bottom: 25px;
  }

  .video {
    width: 300px !important;
  }
}

@media screen and (max-width: 480px) {
  .statisticsSection > div {
    width: 100% !important;
    min-height: 155px;
    margin-bottom: 20px;
  }
  .statisticsSection > div:last-child {
    margin-bottom: 20px;
  }

  .statContent {
    width: calc(100% - 56px);
    min-height: auto;
  }

  .mainDisplayContainer {
    margin-bottom: 40px;
  }
  .infoIcon {
    margin-left: 20px;
  }
  .statTitle {
    text-align: center;
  }
  .statValue {
    text-align: center;
    position: relative;
    top: 0;
    bottom: inherit;
    right: inherit;
  }
}

@media screen and (max-width: 380px) {
  .notificationPaper {
    min-width: inherit;
    margin: 10px;
  }
}

.customerMaintenanceOuter {
  height: calc(100% - 64px);
  top: 64px;
  position: relative;
}

.customerMaintenanceContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.customerMaintenanceSection{
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 310px);
}
.wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    position: relative;
    top: 64px;
    height: calc(100% - 64px);
}

.outer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.inner {
    width: 500px;
    height: 340px;
}

.button-label {
    div {
        line-height: normal;
        vertical-align: middle;
        margin-left: 8px;
    }
}

.login-button {
    max-width: 120px !important;
    min-width: 120px !important;
    background-color: #FFFFFF !important;
}

.login-button:hover {
    background-color: #DDDDDD !important;
}

.login-image {
    display: inline-block;
    width: 218px;
    vertical-align: top;
    height: 340px;
}

.login-heading {
    font-size: 18px;
    font-weight: 300;
    color: #777777;
    margin-top: 0;
    margin-bottom: 16px;
}

.form-div {
    display: inline-block;
    width: 246px;
    height: 270px;
    padding: 35px 18px;

    > form {
        height: 100%;
        position: relative;
    }
}

.action-banner {
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.button-label {
    div {
        line-height: normal;
        vertical-align: middle;
        margin-left: 8px;
    }
}

.buttonLabelProgress {
    position: absolute;
}

.login-error {
    color: #f44336;
    margin: 0;
    font-size: 0.75rem;
    text-align: left;
    margin-top: 16px;
    min-height: 1em;
    line-height: 1em;
}

@media screen and (max-width: 600px) {
    .wrapper {
        justify-content: flex-start;
        margin-top: 20px;
    }

    .inner {
        width: 90%;
    }

    .login-image {
        display: none;
    }

    .form-div {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}

@media screen and (max-width: 480px) {
    .form-div {
        margin-left: 30px;
        margin-right: 0;
        display: block;
    }
}

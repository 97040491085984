.review-job-summary-panel .ag-header-cell{
    padding-right: 0px;
}

.job-review-assignment-panel .job-review-assignment-panel-charges-section{
    display: grid;
    grid-template-columns: auto auto;
}
.job-review-assignment-panel .job-review-assignment-panel-charges-charges-table{
    margin-bottom: unset;
}
.job-review-assignment-panel .job-review-assignment-panel-charges-gp-table .summary-table-label-cell{
    display: none;
}
.lookupInner {
  height: calc(100% + 10px);
}

.browseLookupInner {
  height: 100%;
  display: inline-block;
  background-color: #9A9791
}

.sortingPanelHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 15px;
}

.sortingHandlerContainer {
  height: 15px;
  width: 15px;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}

.lookupSearchSection {
  position: relative;
  width: 280px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  box-sizing: border-box;

  >div:nth-child(2) {
    min-width: 34px !important;
    margin-top: 24px;
  }
}

.lookupNavigationSection {
  width: 200px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: baseline;
  box-sizing: border-box;

  div {
    min-width: 34px !important;
  }
}

.scrollbarStyle {
  height: calc(100% - 110px) !important;
}

.lookupListingList {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-top: 0px;
  /*padding-bottom: 200px !important;  Ensure that the bottom items are always scrollable, regardless of screen size */
}

.lookupListingItemTitleSection {
  overflow-x: hidden;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  align-self: flex-start;
  color: rgba($color: #000000, $alpha: 0.8);
  white-space: nowrap;
  height: auto;
  cursor: unset;
  min-height: 18px;
}

.lookupListingItemMetaSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  box-sizing: border-box;
  justify-content: space-between;
  padding-top: 5px;
  align-self: flex-start;
  font-size: 14px;
  cursor: pointer;
  color: rgba($color: #000000, $alpha: 0.7)
}

.lookupListingItemMetaSectionSingle {
  width: 100%;
  display: inline-block;
  flex-direction: row;
  flex-wrap: nowrap;
  box-sizing: border-box;
  justify-content: space-between;
  padding-top: 5px;
  align-self: flex-start;
  font-size: 14px;
  cursor: unset;
  color: rgba($color: #000000, $alpha: 0.7)
}

.lookupListingItemRowFull {
  flex: 0;
  cursor: pointer;
  max-width: 100%;
  white-space: normal;
}

.lookupListingItemRow1 {
  flex: 1;
  cursor: pointer;
  max-width: 100%;
  white-space: normal;
}

.lookupListingItemRow2 {
  flex: 1;
  max-width: 100%;
  white-space: normal;
  cursor: unset;
}

.alignRight {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.lookupListingItemUser {
  flex: 3;
  cursor: pointer;
}

.filterMenuPaper {
  box-sizing: border-box;
  position: absolute;
  width: 280px;
  top: 67px;
  height: auto;
  z-index: 100;
  padding: 15px;
}

.helperText {
  font-size: 12px;
  padding-left: 12px;
  margin-top: -15px;
  color: rgba(0, 0, 0, 0.87);
}

.loadingItem {
  width: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  box-sizing: border-box;
  justify-content: space-between;
  padding-top: 5px;
  align-self: flex-start;
  font-size: 14px;
  cursor: pointer;
  color: rgba($color: #000000, $alpha: 0.7)
}
.checklistOuter {
    height: calc(100% - 64px);
    top: 64px;
    position: relative;
  }
  
  .checklistContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .checklistSection{
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin-left: 10px;
  }

.left-icons{
  flex: 1;
}
.bottomNavigationBar {
  height: 67px !important;
  width: 100%;
  position: fixed;
  bottom: 0;
  overflow: hidden;
  -webkit-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.35);
  -moz-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.35);
  box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.35);
  z-index: 5;
}
.navigationItemStyle {
  width: 50px;
  box-sizing: border-box;
}

@media screen and (max-width: 480px) {
  .navigationItemStyle {
    width: 40px;
    padding: 8px 0 0 !important;
    min-width: 60px !important;
  }
  .left-icons {
    flex: 0 !important;
  }
}
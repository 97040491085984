.currentBreadcrumb {
    color: #ffffff;
}
.trailBreadcrumb {
    color: #919191;
}
.cursorPointer{
    cursor: pointer;
}
.breadcrumbBar {
    padding: 0 0 0 10px !important;
    background-color: #222222 !important;
    height: 40px;
}
.breadcrumbText {
    margin-top: 0px;
    font-size: 22px;
    line-height: 40px;
    font-weight: 400;
    height: 40px;
}
.iconOptionsMenu {
    height: 40px;
    width: 40px;
    box-sizing: border-box;
    padding: 8px 8px;
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer;
    background-color: #333333;
}

@media screen and (max-width: 480px) {
    .breadcrumbText {
        font-size: 18px !important;
        position: relative;
        top: -3px;
    }
    .trailBreadcrumb {
        display: none;
    }
}
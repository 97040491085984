.lookupInner {
    height: calc(100% - 67px);
  }
  
.lookupDragController {
    position: fixed;
    top: calc(64px);
    left: 0;
    width: 20px;
    height: calc(100% - 67px - 64px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #F47920 !important;
    cursor: pointer;
    -webkit-box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.45) !important;
    -moz-box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.45) !important;
    box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.45) !important;
    z-index: 1;
}
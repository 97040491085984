@media screen and (max-width: 480px) {
    .tabItem {
        display: none !important;
    }
    .tabSection > div {
        min-height: auto !important;
    }
    .tabSection > div > div:first-child {
        display: none !important;
    }
}
.rootDiv {
  height: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
}
.iconDiv {
  flex: 1;
  height: 35px;
  padding: 5px;
}

.contentSection {
  flex: 6;
  box-sizing: border-box;
  font-size: 13px;
  padding: 5px;
}
.notificationConent {
  height: auto;
  overflow: wrap;
  width: 240px;
}
.footerSection {
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;
  height: 30px;
  padding-top: 10px;
}
.metaContainer {
  flex: 3;
  color: grey;
  font-size: 12px;
  font-weight: 200;
  padding-top: 5px;
}
.actionContainer {
  flex: 1;
  text-align: center;
}
.actionButton {
  font-size: 10px;
  font-weight: 400;
  height: 20px;
  line-height: 20px;
}
.separator {
  width: 80%;
  height: 1px;
  background-color: #999999;
  margin-left: 10%;
}
.wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  position: relative;
}
.loadingOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #80808045;
  z-index: 999;
}

.outer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.inner {
  width: 700px;
  height: 600px;
}

.innerV2 {
  height: 550px;
}

.controlHeaderBlock {
  width: 100%;
  height: 190px;
  background-color: #dddddd;
  box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  padding: 15px 15px 0 15px;
}

.controlHeaderBlockV2{
  height: 140px;
}

.expansionContainerBlock {
  width: 100%;
  height: 358px;
  box-sizing: border-box;
  padding: 10px;
  padding-right: 0;
}

.actionFooterBlock {
  height: 52px;
  background-color: #dddddd;
  box-shadow: 0 -5px 10px -2px rgba(0, 0, 0, 0.35);
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  justify-content: flex-end;
  padding: 0 15px;
}

.titleHeading {
  font-size: 18px;
  font-weight: 300;
  color: #777777;
  margin-top: 0;
  margin-bottom: 0;
}

.documentSelectorBlock {
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  justify-content: space-between;
}

.additionalSelectorsBlock {
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  margin-top: -16px;
}

.additionalSelectorsBlockV2 {
  margin-top: 0px;
}

.commentsLabel {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 400;
  margin-left: 30px;
}

.separator {
  margin: 0px;
}

.toggleSelectorsBlock {
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
}

.outputSelectionLabel {
  width: 140px;
  font-size: 14px;
  font-weight: 400;
  color: #555555;
}

.toggleButtonContainer {
  width: calc(100% - 20px - 140px);
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  justify-content: space-between;
}

.expansionSummaryTitle {
  width: 59px;
  opacity: 0.54;
  font-family: Roboto;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.54);
}

.formFieldRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  justify-content: space-between;
}

.singleColumnForm {
  width: 47.5%;
}

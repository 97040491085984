.wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    height: calc(100% - 40px - 40px - 75px);
}

.wrapper-no-tabs {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    height: calc(100% - 40px - 67px);
}
.GridContainer {
    height: 100%;
}
.GridContainer > div {
    height: 100%; // Fix for Mozilla and IE to ensure container is full height
}
.FormGridWithSummaryTable {
    width: calc(100% - 350px);
}

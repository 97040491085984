.worksaleOuter {
  height: calc(100% - 64px);
  top: 64px;
  position: relative;
}

.worksaleContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.worksaleSection {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
.opened {
  margin-left: 10px;
  width: calc(100% - 310px)
}
.closed {
  width: calc(100% - 20px);
  margin-left: 20px;
}
